<template>
<header class="header container-fluid">
  <div class="row text-center">
    <div class="col-6 col-md-4 offset-md-4">
      <img src="@/assets/images/rhombus-logo.png" alt="The Rhobmbus Logo" class="site-logo img-fluid">
    </div>
    <div class="col-6 col-md-4">
      <a href="https://www.rhombusanalytics.com/index.html" class="return" id="nav-link"><span>View Full Rhombus™ Site</span><img src="@/assets/images/right-arrow.png" alt="Right arrow"></a>
    </div>
    <div class="col-12">
      <h1>Manage Your Tires<br>Without Spinning Your Wheels.</h1>
    </div>
  </div>
  <img src="@/assets/images/three-tires.png" alt="Three tires with blue-stroked treads" class="tires img-fluid">
</header>
</template>

<script>
export default {
  name: 'Header',
}
</script>
