<template>
  <div>
    <Header />
    <HelloWorld />
    <Footer />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import 'bootstrap/dist/css/bootstrap.css'



export default {
  name: 'App',
  components: {
    Header,HelloWorld,Footer
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/main.scss';
</style>

