<template>
  <div class="col-12">
    <formulate-form v-model="formValues" @submit="submitForm" id="contact-form" name="contactForm">
      <div class="row">
        <div class="col-12 col-md-6">
          <formulate-input type="text" name="firstName" label="First Name*" validation="required"
            outer-class="form-group" input-class="form-control"></formulate-input>
        </div>
        <div class="col-12 col-md-6">
          <formulate-input type="text" name="lastName" label="Last Name*" validation="required" outer-class="form-group"
            input-class="form-control"></formulate-input>
        </div>
        <div class="col-12 col-md-6">
          <formulate-input type="email" name="email" label="Email Address*" validation="required|email"
            outer-class="form-group" input-class="form-control"></formulate-input>
        </div>
        <div class="col-12 col-md-6">
          <formulate-input type="text" name="phone" label="Phone Number*" validation="required" outer-class="form-group"
            input-class="form-control"></formulate-input>
        </div>
        <div class="col-12">
          <formulate-input type="textarea" name="message" label="Message*" validation="required"
            outer-class="form-group" input-class="form-control"></formulate-input>
        </div>
        <div class="col-12 text-center">
          <formulate-input type="submit" label="Submit" input-class="btn btn-primary btn-xl text-uppercase" id="form-submit" v-on:click="measureButtonClick('Request demo', 'Form submit')"
            :disabled="isSubmitting"></formulate-input>
        </div>
      </div>
      <div class="row my-4" v-if="success">
        <div class="col-12 ">
          <div class="alert alert-success text-center">
            {{ alert }}
          </div>
        </div>
      </div>
      <div class="row my-4" v-if="danger">
        <div class="col-12">
          <div class="alert alert-danger text-center">
            {{ alert }}
          </div>
        </div>
      </div>
    </formulate-form>

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate)

export default {
  name: 'Contact',
  data: function () {
    return {
      formValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
      },
      alert: '',
      success: false,
      danger: false,
      isSubmitting: false
    }
  },

  methods: {
    measureButtonClick(category, label) {
      this.$gtag.event('Button Click', {
        event_category: category,
        event_label: label
      })
    },
    submitForm: function () {
      var domain = 'www';
      if (window.location.href.indexOf("dev") > -1) {
        domain = 'dev';
      }

      this.isSubmitting = true;
      axios.post('https://' + domain + '.rhombusinfo.com/lead-gen/lead-gen-form.php', {
        firstName: this.formValues.firstName,
        lastName: this.formValues.lastName,
        email: this.formValues.email,
        phone: this.formValues.phone,
        message: this.formValues.message,
      }).then(function (response) {
        if (response.status === 200 && response.data) {
          this.alert = "Thank you for your submission. One of our representatives will be in touch with you!";
          this.success = true;
          this.$formulate.reset('contactForm');
        } else {
          this.alert = "There was an error processing your request. Please try again.";
        }
        this.isSubmitting = false;
      }.bind(this));
    },
  }
}

</script>