<template>
  <div class="hello">
    <div class="bg-contrast">
      <main class="container introduction">
        <div class="row">
          <img src="@/assets/images/responsive-display.png" alt="The new Rhombus tool displayed on multiple devices at varying screen widths" class="responsive-overhang">
          <div class="col-12 pb-5 text-center">
            <a href="#contact" id="form-scroll" class="btn btn-primary btn-xl text-uppercase">Request More Info</a>
          </div>
          <div class="col-12">
            <p class="lead text-center pb-5">Rhombus™ TireAnalytics by Dana is an easy-to-use, fully digital tire management system that offers complete tire tracking and visibility across your entire organization. Rhombus is proven to:</p>
          </div>
        </div>
        <div class="row product-highlights">
          <div class="col-12 col-md-6 col-xl-4 text-center pb-5">
            <img src="@/assets/images/blue-check.png" alt="A blue checkmark" class="pb-4">
            <p>Help reduce breakdowns</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4 text-center pb-5">
            <img src="@/assets/images/blue-check.png" alt="A blue checkmark" class="pb-4">
            <p>Increase uptime and tire lifespan</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4 text-center pb-5">
            <img src="@/assets/images/blue-check.png" alt="A blue checkmark" class="pb-4">
            <p>Help make informed tire purchases </p>
          </div>
          <div class="col-12 col-md-6 col-xl-4 text-center pb-5">
            <img src="@/assets/images/blue-check.png" alt="A blue checkmark" class="pb-4">
            <p>Improve compliance and reduce CSA violations </p>
          </div>
          <div class="col-12 col-md-6 col-xl-4 text-center pb-5">
            <img src="@/assets/images/blue-check.png" alt="A blue checkmark" class="pb-4">
            <p>Implement and monitor tire maintenance processes across multiple shops</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4 text-center pb-5">
            <img src="@/assets/images/blue-check.png" alt="A blue checkmark" class="pb-4">
            <p>Get real-time reporting and complete data analysis for PSI, tread depth, condition history, cost per mile, and more</p>
          </div>
        </div>
      </main>
    </div>
    <section class="container-fluid">
      <div class="row testimonials">
        <div class="col-12 text-center">
          <h2 class="text-white">Testimonials</h2>
        </div>
        <div class="col-12">
          <div class="testimonial-slider">
            <tiny-slider :mouse-drag="true" items="3" gutter="20" :controls="false">
              <div class="testimonial">
                <blockquote><span>Rhombus helps us extend the life of our tires and saves us money!</span>
                  <cite>Jennifer Michener, DeBauche Idealease</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>Rhombus proved we had more extensive issues than we realized.</span>
                  <cite>Bryan Larkin, RWC Idealease</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>We’ve experienced a 12% year-over-year reduction in tire expense.</span>
                  <cite>Mario Delgado, Idealease Atlanta</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>It’s the sole reason we reduced our over-the-road tire spend by 49%.</span>
                  <cite>Dan Shandy, Matheson Trucking</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>The onboarding process was easy! Training was fast, efficient, and comprehensive.</span>
                  <cite>Frank Rinaudo, Rinaudo Enterprises, Inc.</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>I have seen a drop in tire expenses by 10% or more.</span>
                  <cite>Eric Mariscal, Inland Kenworth PacLease</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>Rhombus helped define and organize data points into actionable information.</span>
                  <cite>Rick Tapp, PACCAR Leasing Co</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>I would recommend Rhombus to anyone looking at tire analytics.</span>
                  <cite>Matt England, Pottle’s Transportation</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>WOW! That was much easier than I expected.</span>
                  <cite>Todd Cotier, Hartt Transportation Systems</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>
                    We have learned from the alerts and are now proactive in managing our tires.</span>
                  <cite>Joey Pinette, Hartt Transportation Systems</cite>
                </blockquote>
              </div>
              <div class="testimonial">
                <blockquote><span>Our miles per thirty-second (MPT) have doubled in the first 20 months.</span>
                  <cite>Mario Delgado, Idealease Atlanta</cite>
                </blockquote>
              </div>
            </tiny-slider>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row contact">
        <img src='@/assets/images/tires.png' alt="Multiple tires displayed head-on at slightly varied angles">
        <div class="col-12 text-center">
          <p><strong>Save time. Save money. And save yourself the headache from downtime.</strong></p>
          <a href="javascript:void();" id="contact"></a>
          <p>Request More Info Today!</p>
        </div>
        <Contact />
        <div class="col-12 col-md-4 offset-md-4 text-center">
          <p>A Rhombus Digital Sales and Service Manager will contact you soon.</p>
        </div>
        <div class="col-12">
          <div class="overhang-container">
            <div class="overhang">
              <p>or</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-contrast pt-5">
      <div class="container-fluid representative-cta py-5">
        <div class="container">
          <div class="col-12 text-center">
            <a href="tel:18778636506" class="telephone-cta mb-3">
              <img src="@/assets/images/headset-with-microphone.png" alt="An icon of a headset with a microphone">
              <p>Contact a Representative<br>
                <span>Call 1-877-863-6506</span>
              </p>
            </a>
            <p class="safety-notice py-3">SAFETY IS ALWAYS TOP OF MIND: All in-person meetings and installations will be carefully conducted, and following all state and local PPE and social distancing laws or guidelines.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider'
import Contact from '../components/Contact'



export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {
    'tiny-slider': VueTinySlider,
    Contact
  },
  data: function () {
    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
