<template>
  <footer class="footer">
    <div class="container">
      <div class="row py-5">
        <div class="col-12 contact-container">
          <ul>
            <li><a href="https://apps.apple.com/gb/app/tireanalytics/id1183035167"><img src="@/assets/images/app-store.png" alt="An icon and logo for the Apple App Store"></a></li>
            <li><a href="https://play.google.com/store/apps/details?id=com.phasezeroventures.tireanalytics"><img src="@/assets/images/google-play-badge.png" alt="An icon and logo for the Google Play Store"></a></li>
          </ul>
          <p><strong>Dana Commercial Vehicle Driveline Technologies</strong><br>3939 Technology Drive, Maumee, Ohio, USA 43537</p>
          <p>© 2020 Dana Limited.</p>
          <p><a href="https://www.rhombusanalytics.com/privacypolicy.html" target="_blank">Privacy Policy</a></p>
        </div>
        <div class="col-12">
          <div class="legal-container">
            <p class="legal">Rhombus™ is a trademark of Dana Corporation.</p>
            <p class="legal">Android, Google Play and the Google Play logo are trademarks of Google LLC.</p>
            <p class="legal">Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc., registered in the U.S. and other countries.</p>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
