import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import axios from 'axios'
import 'popper.js/dist/popper.min.js';
import 'bootstrap'

Vue.use(VueGtag, {
    config: {
        id: "UA-176164712-2",
        params: {
            send_page_view: true
        }
    },
});

Vue.config.productionTip = false

Vue.prototype.$http = axios;

new Vue({
    render: h => h(App)
}).$mount('#app')